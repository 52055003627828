import { Layout, ViewTable } from "@/components";
import React from "react";
import columns from '@/page/purchase_order/list/purchase_order_columns';
import WebApi from "@/web/api";
import dayjs from "dayjs";

export default class PurchaseOrderDetailReportPage extends React.PureComponent<RouterPropsType> {
  render() {
    let date = this.props.match?.params.date;
    let dateRange: [string, string] = [date, date];
    const supplierId = this.props.match?.params.supplierId;
    const supplierName = this.props.match?.params.supplierName;
    if (supplierId) {
      date = date.split('-').map(i => dayjs(i, 'YYYYMMDD').format('YYYY.MM.DD')).join(' - ');
      dateRange = date.split('-').map(i => dayjs(i, 'YYYYMMDD').format('YYYY-MM-DD'))
    }

    return <Layout.Page>
      <Layout.Toolbar title={<span>
        {
          supplierName &&
          <span style={{marginRight: 30}}>供应商：{supplierName}</span>
        }
        日期：{date}
      </span>}></Layout.Toolbar>
      <ViewTable
        flex1
        columns={columns as any}
        fetch={(data) => {
          return WebApi.purchase_order_paging({...data, dateRange});
        }}
      />
    </Layout.Page>
  }
}