import React from 'react';
import { routerMap } from '@/router/agent_router';
import { Link, Status } from '@/components';
import { payment2EnumMap, purchaseEnumMap } from '@/enum-data/enum';

export default [
  {
    title: '进货单号',
    dataIndex: 'purchaseId',
    width: 200,
    align: 'center',
    fixed: true,
    render: (text, record) => (
      <Link to={routerMap.purchaseOrderDetail.getPath(record.purchaseId)}>{text}</Link>
    ),
  },
  {
    title: '供应商',
    dataIndex: 'supplierName',
    width: 150,
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatusDesc',
    width: 100,
    align: 'center',
    render: (text, record) => {
      return <Status.Order status={purchaseEnumMap(record.orderStatus) as any}>{text}</Status.Order>
    },
  },
  {
    title: '付款状态',
    dataIndex: 'paymentStatusDesc',
    width: 100,
    align: 'center',
    render: (text, record) => {
      return <Status.Payment status={payment2EnumMap(record.paymentStatus) as any}>{text}</Status.Payment>
    },
  },
  {
    title: '下单时间',
    dataIndex: 'createdAt',
    align: 'center',
    width: 200,
  },
  {
    title: '订单金额',
    dataIndex: 'totalAmount',
    align: 'right',
    sorter: true,
    width: 100,
  },
  {
    title: '商品种类',
    dataIndex: 'productKind',
    width: 150,
    sorter: true,
    align: 'right'
  },
  {
    title: '商品数量',
    dataIndex: 'productCount',
    width: 150,
    sorter: true,
    align: 'right'
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 200,
  },
];